<template>
  <div class="table-full">
    <div class="table-full__container">
      <TableMundial
        v-if="isCup"
        :custom="colorsConfig"
        :seasonget="numberSeasonId"
        await-for-season
        is-single-component
      />
      <Table v-else :season="numberSeasonId" :custom="colorsConfig" is-single-component />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TableFull',
  components: {
    Table: () => import('@/components/Sections/Table/TableComponent'),
    TableMundial: () => import('@/components/Sections/Table/TableComponentMundial'),
  },
  data() {
    return {
      colorsConfig: {
        logo: 'https://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        color: '#cbee6b',
        background: '#242424',
        colorheader: '#132839',
        backgroundheader: '#CCE8B5',
        headerBackground: '#132839',
        headerColor: '#CBEE6E',
        headerBorderColor: '#CBEE6E',
        header2Background: '#132839',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cbee6b',
        subHeaderColor: '#353535',
        headerBetToolBackground: '#242424',
        subheaderBetToolBackground: '#151515',
        bet_house: '',
        main_logo: '',
        secondary_logo: '',
        show_logo: false,
        url_base: '',
        type: 1,
        team_id: 0,
        categories: 'all',
      },
    };
  },
  computed: {
    ...mapState('leagueGeneral', ['objectTournamentSelected', 'objectSeasonSelected']),
    isCup() {
      return !!this.objectTournamentSelected.tournament_type;
    },
    numberSeasonId() {
      return this.objectSeasonSelected.id || 0;
    },
  },
};
</script>

<style scoped lang="scss">
.table-full {
  display: inline-block;
  margin: 0 auto;
  max-width: 2000px;
  padding-bottom: 70px;
  width: 96.2%;
  height: 100%;
  border-radius: 21px;
  position: relative;
  box-shadow: 0 7px 24px 0 rgba(84, 124, 171, 0.12);
  background-color: #ffffff;

  &__container {
    width: 1270px;
    height: 100%;
    margin: 19px auto 0;
    @media screen and (max-width: 1328px) {
      width: 98%;
    }
  }
}
</style>
